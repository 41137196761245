import { defineStore } from 'pinia'

export const useGeneralStore = defineStore("general", {
    state: () => ({
        currentLanguage: "de",
        currentLanguageID: "1021",
        darkMode: false,
        currentArticleID: null,
        articleList: [],
        companyData: {},
        configStep: 1,
        visitorName: '',
        useVisitorName: true,
        blobSrc: '',
        configEnded: false,
        configStarted: false,
        marketingCookies: false,
        flowSpeed: 1.0
    }),

    actions: {
        /**
         * 
         * @param {*} payload 
         */
        setMarketingCookies (payload) {
            this.marketingCookies = payload
            console.info("Set Marketing Cookies to", payload)
        },
        /**
         *
         * @param {*} language
         */
        async saveLanguage (language) {
            this.currentLanguage = language;
            localStorage.setItem('wsLanguage', language);

            const { data } = await useFetch(`https://administration.w-s.app/api/v1/services/languages`);

            if (data.value) {
                let currentLanguage = data.value.filter((ele) => ele.iso === language)
                this.currentLanguageID = currentLanguage[0].id
            }
        },
        
        /**
         *
         * @param {*} lang
         * @param {*} path
         */
        async setArticleList(lang, path) {
            const { data } = await useFetch(`https://administration.w-s.app/api/v1/services/articles/${lang}`);

            if (data.value) {
                if (window.location.hostname === 'walbert-schmitz.de') {
                // if (window.location.hostname === 'localhost' || window.location.hostname === 'walbert-schmitz.de') {
                    let filteredArticleList = data.value.filter((ele) => ele.pageStatus !== 'hidden')

                    this.articleList = filteredArticleList;
                    localStorage.setItem('wsArticleList', JSON.stringify(filteredArticleList));
                } else if (window.location.hostname === 'localhost' || window.location.hostname === 'staging.w-s.app') {
                // } else if (window.location.hostname === 'staging.w-s.app') {
                    this.articleList = data.value;
                    localStorage.setItem('wsArticleList', JSON.stringify(data.value));
                }
            }

            if (path) {
                this.getArticleID(path)
            }
        },

        /**
         *
         * @param {*} currentPath
         */
        getArticleID(currentPath) {
            const pageMeta = this.articleList.find(({ path }) => path === currentPath);

            if(pageMeta != undefined) {
                this.currentArticleID = pageMeta.id;
            } else {
                this.currentArticleID = false;
            }
        },

        /**
         * 
         * @param {*} processID 
         */
        setProcessID(processID) {
            this.currentArticleID = processID;
        },

        /**
         * 
         * @returns this.currentArticleID
         */
        getProcessID() {
            return this.currentArticleID;
        },

        /**
         *
         * @param {*} lang
         */
        async createCompanyData (lang) {
            const { data } = await useFetch(`https://administration.w-s.app/api/v1/services/base-data/${lang}`);

            if (data.value) {
                this.companyData = data.value;
            }
        },

        /**
         *
         * @param {*} payload
         */
        saveDarkMode (payload) {
            this.darkMode = payload;
            localStorage.setItem('wsDarkMode', payload);
        },

        /**
         *
         * @param {*} language
         */
        setConfigStep (step) {
            this.configStep = step;
        },

        /**
         * 
         * @param {*} name 
         */
        setVisitorName(name) {
            this.visitorName = name;
            localStorage.setItem('visitorName', name)
        },

        /**
         *  
         */
        toggleUseVisitorName() {
            this.useVisitorName = !this.useVisitorName;
            localStorage.setItem('useName', this.useVisitorName)
        },

        /**
         * 
         * @param {*} name 
         */
        changeBlobSrc(attr, value) {
            let blobStr = this.blobSrc
            let blobArr = blobStr.split('_')

            if (attr === 'idle' && blobArr.length <= 2) {
                this.blobSrc = 'idle_' + (this.darkMode ? 'dark' : 'light')
            } else if (attr === 'dark') {
                blobArr[blobArr.length - 1] = this.darkMode ? 'dark' : 'light'
                this.blobSrc = blobArr.toString().replaceAll(',', '_')
            } else if (blobArr.length > 2) {
                blobArr[attr] = value
                blobArr[3] = this.darkMode ? 'dark' : 'light'
                this.blobSrc = blobArr.toString().replaceAll(',', '_')

                localStorage.setItem('blob-fluffy', blobArr[0])
                localStorage.setItem('blob-organic', blobArr[1])
                localStorage.setItem('blob-monochrome', blobArr[2])
            } else {
                this.blobSrc = 'fluffy_organic_monochrome_' + (this.darkMode ? 'dark' : 'light')
            }

            localStorage.setItem('blob', this.blobSrc)
        },

        /**
         * 
         * @param {*} name 
         */
        setBlobSrc(value) {
            this.blobSrc = value
            localStorage.setItem('blob', this.blobSrc)
        },

        /**
         *  
         */
        resetBlob() {
            this.setVisitorName('')
            this.configEnded = false
            this.blobSrc = 'fluffy_organic_monochrome_' + (this.darkMode ? 'dark' : 'light')

            localStorage.setItem('configEnded', false)
            localStorage.setItem('blob', this.blobSrc)
            localStorage.removeItem('blob-fluffy')
            localStorage.removeItem('blob-organic')
            localStorage.removeItem('blob-monochrome')
        },

        /**
         *  
         */
        startConfig() {
            this.configStarted = true            
        },

        /**
         *  
         */
        endConfig() {
            this.configEnded = true
            
            localStorage.setItem('configEnded', true)
        },

        /**
         *  
         */
        setFlowSpeed(speed) {
            this.flowSpeed = speed
            
            localStorage.setItem('flowSpeed', speed)
        },
    }
})