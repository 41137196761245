<template>
  <div class="media-container">
    <Transition name="fade-in">
      <PreloaderIcon v-if="visible" />
    </Transition>

    <Transition name="fade-in">
      <video
        :class="props.class"
        :data-id="'vid-' + videoID"
        :id="'video-' + videoID"
        loop
        muted
        playsinline
      >
        <!-- <source :src="cachedVideoSrc" :type="'video/' + props.type"> -->
        {{ props.alt }}
      </video>
    </Transition>
  </div>
</template>

<script setup>
import { MediaCache } from "@/assets/js/FileCache.cls";
import { v4 as uuidv4 } from "uuid";
import { useGeneralStore } from "@/stores/general"

const generalStore = useGeneralStore()
const { blobSrc, flowSpeed } = storeToRefs(generalStore)

const visible = ref(false);

const props = defineProps({
  src: String,
  alt: String,
  class: String,
  type: String,
});

let cachedVideoSrc = ref('');
let bufferedVideo = ref(null);
let videoDOMEle = ref('');
let videoEle;
let videoID = uuidv4();

const renderMedia = () => {
  let render = new MediaCache(props.src, (visible.value = true));
  // const videoSource = document.querySelector('')
  videoDOMEle.value = document.querySelector("[data-id=vid-" + videoID + "]")
  
  render
    .setVideo()
    .then(res => {
      // videoDOMEle.value.src = res
      videoDOMEle.value.src = props.src
      videoDOMEle.value.load()
      videoDOMEle.value.onloadeddata = () => {
        visible.value = false
        videoDOMEle.value.playbackRate = props.class === 'flow-video' ? flowSpeed.value : 1.0
        videoDOMEle.value.play()
      }
    })

};

watch(blobSrc, () => {
  renderMedia()
})

const stopMedia = (vid) => {
  vid.pause();
};

onMounted(() => {
  setTimeout(() => {
    videoDOMEle.value = document.querySelectorAll("[data-id=vid-" + videoID + "]");

    renderMedia(videoDOMEle[0]);
  }, 800);
});

// onBeforeUnmount(() => {
//     stopMedia(videoDOMEle);
// });
</script>
