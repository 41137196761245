class FileCache {
  constructor(src, fnc) {
    this.src = src;
    this.fnc = fnc;
  }

  getSource() {
    return this.src;
  }
}

class MediaCache extends FileCache {
  constructor(src, fnc) {
    super(src, fnc);
  }

  setImage() {
    return new Promise((resolve, reject) => {
      let image = new Image();
      image.onprogress = this.fnc; // during loading process a loading progress can be triggered
      image.onload = () => resolve(this.src);
      image.onerror = () => reject(this.src);
      image.src = this.src;
    });
  }

  async setVideo() {
    let video = new Blob([this.src], {"type": "video\/mp4"})

    const videoUrl = URL.createObjectURL(video)
    
    return videoUrl
  }
}

export { FileCache, MediaCache };
