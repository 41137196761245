import { defineStore } from 'pinia'

export const useMainContentStore = defineStore('mainContent', {
    state: () => ({
        pageTitle: null,
        pageDescription: null,
        pageKeywords: null,
        pageAuthor: null,
        pageContent: {},
        subPageContent: [],
        resort: false
    }),
    actions: {
        /**
         *
         * @param {*} lang
         * @param {*} processID
         */
        async createPageContent (lang, processID, resort = false) {
            const config = useRuntimeConfig();
            const { data } = await useFetch(`${config.public.apiBase}/microsite/${processID}/${lang}`);

            if (data.value) {
                if (window.location.hostname === 'localhost' || window.location.hostname === 'walbert-schmitz.de') {
                    let meta = data.value.pageMeta;
                    let widgets = [];

                    data.value.widgets.forEach((widget) => {
                        if (widget.meta.pageStatus !== 'hidden') {
                            if (widget.meta.widgetType === 'gridListWidget') {
                                let gridList = {
                                    'meta': widget.meta,
                                    'content': {
                                        'title': widget.content.title,
                                        'subheadline': widget.content.subheadline,
                                        'headline': widget.content.headline,
                                        'gridItems': widget.content.gridItems.filter((ele) => ele.pageStatus !== 'hidden')
                                    }
                                };

                                widgets.push(gridList)
                            } else {
                                widgets.push(widget);
                            }
                        }
                    })

                    let finalData = {
                        'pageMeta': meta,
                        'widgets': widgets
                    }

                    this.pageContent = finalData;
                    this.pageTitle = meta.pageTitle;
                    this.pageDescription = meta.description;
                    this.pageKeywords = meta.keywords;
                    this.pageAuthor = meta.author;

                    // @TODO: additional optional resorter function, should be used if stage experience mode is set fast!
                    if (resort) {
                        this.reorderContent(true)
                    }

                } else if (window.location.hostname === 'staging.w-s.app') {
                    this.pageContent = data.value;
                    // @TODO: additional optional resorter function, should be used if stage experience mode is set fast!
                    if (resort) {
                        // this.reorderContent(this.pageContent.widgets, 3)
                    }
                }
            }
        },

        /**
         * @TODO: this is a static version of resorting the items, should be replaced by a dynamic function based on any given identifier
         * @param {ObjectArray} _array 
         * @param {Number} iterations 
         */
        resortArray (_array) {
            _array.sort((x, y) => {
                return (x.meta.options.sortable === y.meta.options.sortable) ? 0 : x.meta.options.sortable ? -1 : 1
            })

        },

        /**
         * 
         * @param {Boolean} payload 
         */
        resortValue (payload) {
            this.resort = payload;
            localStorage.setItem("resort", payload)
        },

        getResortValue () {
            let value = localStorage.getItem("resort")
            console.log("GET RESORT VALUE", value)

            if (value === "true") {
                this.resort = true
                this.reorderContent(true)
            } else {
                this.resort = false
            }
        },

        /**
         *
         * @param {*} payload
         */
        createSubPageContent (payload) {
            this.subPageContent = payload;
        },

        clearPageContent () {
            this.pageContent = {};
        },

        getPageTitle () {
            return this.pageTitle;
        },

        getPageDescription () {
            return this.pageDescription;
        },

        getPageKeywords () {
            return this.pageKeywords;
        },

        getPageAuthor () {
            return this.pageAuthor;
        },

        /**
         * 
         * @param {*} payload 
         * @param {*} iterations 
         */
        reorderContent (payload) {
            if (payload === true) {
                this.resortArray(this.pageContent.widgets)
            }
        }
    }
})
