<template>
  <div v-if="props.darkMode" class="option-switch row">
    <div 
      class="switch__container"
      :class="props.menuSwitch ? 'order-2' : ''"
    >
      <label class="switch dark-mode-switch">
        <input
          class="dark-mode-checkbox"
          type="checkbox"
          :checked="darkMode"
          @change="toggleDarkMode()"
        />
        <span class="slide-pin"></span>
      </label>
    </div>

    <div 
      class="label__container column justify-around q-pl-md"
      :class="props.menuSwitch ? 'order-1 q-pr-md' : ''"
    >
      <span
        class="switch__label switch-off"
        :class="{ 'is-active': !darkMode }"
        @click="toggleDarkMode(false)"
      >
        {{ props.option1 }}
      </span>

      <span
        class="switch__label switch-on"
        :class="{ 'is-active': darkMode }"
        @click="toggleDarkMode(true)"
      >
        {{ props.option2 }}
      </span>
    </div>
  </div>

  <div v-else-if="props.nameSwitch" class="option-switch row">
    <div class="switch__container">
      <label class="switch dark-mode-switch">
        <input
          class="dark-mode-checkbox"
          type="checkbox"
          :checked="!useVisitorName"
          @change="generalStore.toggleUseVisitorName()"
        />
        <span class="slide-pin"></span>
      </label>
    </div>

    <div class="label__container column justify-around q-pl-md">
      <span
        class="switch__label switch-off"
        :class="{ 'is-active': useVisitorName }"
        @click="generalStore.toggleUseVisitorName()"
      >
        {{ props.option1 }}
      </span>

      <span
        class="switch__label switch-on"
        :class="{ 'is-active': !useVisitorName }"
        @click="generalStore.toggleUseVisitorName()"
      >
        {{ props.option2 }}
      </span>
    </div>
  </div>

  <div v-else class="option-switch row">
    <div class="switch__container">
      <label class="switch dark-mode-switch">
        <input
          class="dark-mode-checkbox"
          type="checkbox"
          v-model="checked"
          @change="toggleOption(props.attr, checked)"
        />
        <span class="slide-pin"></span>
      </label>
    </div>

    <div class="label__container column justify-around q-pl-md">
      <PreloaderIcon v-if="props.pseudoLoader && isLoading" :configPreloader="true" />

      <span
        v-if="(props.pseudoLoader && !isLoading) || !props.pseudoLoader"
        class="switch__label switch-off"
        :class="checked ? '' : 'is-active'"
        @click="toggleOption(props.attr, false)"
      >
        {{ props.option1 }}
      </span>

      <span
        v-if="(props.pseudoLoader && !isLoading) || !props.pseudoLoader"
        class="switch__label switch-on"
        :class="checked ? 'is-active' : ''"
        @click="toggleOption(props.attr, true)"
      >
        {{ props.option2 }}
      </span>
    </div>
  </div>
</template>

<script setup>
import { useGeneralStore } from "@/stores/general";
import { useMainContentStore } from "@/stores/mainContent";

const props = defineProps({
  darkMode: Boolean,
  nameSwitch: Boolean,
  pseudoLoader: Boolean,
  menuSwitch: Boolean,
  attr: String,
  option1: String,
  option2: String,
});

const contentStore = useMainContentStore();
const generalStore = useGeneralStore();
const { darkMode, useVisitorName, flowSpeed } = storeToRefs(generalStore);

const checked = ref("")
const isLoading = ref(false)

if (localStorage.getItem("resort") && props.attr === 'reorder') {
  checked.value = localStorage.getItem("resort") === "true" ? true : false
}

if (localStorage.getItem("blob-" + props.attr)) {
  let currentAttrVal = localStorage.getItem("blob-" + props.attr);

  switch (props.attr) {
    case "fluffy":
      checked.value = currentAttrVal === "fluffy" ? false : true;
      break;
    case "organic":
      checked.value = currentAttrVal === "organic" ? false : true;
      break;
    case "monochrome":
      checked.value = currentAttrVal === "monochrome" ? false : true;
      break;
  }
}

const toggleDarkMode = (value) => {
  if (value) {
    generalStore.saveDarkMode(value);
  } else {
    generalStore.saveDarkMode(!darkMode.value);
  }

  generalStore.changeBlobSrc("dark");
};

const toggleOption = (option, value) => {
  if (props.pseudoLoader) {
    isLoading.value = true
  }

  if (value !== null) {
    checked.value = value;
  }

  switch (option) {
    case "reorder":
      contentStore.resortValue(value);
      contentStore.reorderContent(value);

      let wallpaperVideoEle  = document.querySelector('#flow-video video')

      if (value) {
        generalStore.setFlowSpeed(1.5)
      } else {
        generalStore.setFlowSpeed(1.0)
      }

      wallpaperVideoEle.pause()
      wallpaperVideoEle.playbackRate = flowSpeed.value
      wallpaperVideoEle.play()
      break;
    case "fluffy":
      generalStore.changeBlobSrc(0, checked.value ? "smooth" : "fluffy");
      break;
    case "organic":
      generalStore.changeBlobSrc(1, checked.value ? "geometric" : "organic");
      break;
    case "monochrome":
      generalStore.changeBlobSrc(2, checked.value ? "color" : "monochrome");
      break;
  }

  setTimeout(() => {
    isLoading.value = false
  }, 1000);
};
</script>
