<template>
  <div 
    class="loader"
    :class="props.configPreloader ? 'config-loader' : ''"
  >
    <div class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>

    <div
      v-if="props.configPreloader"
      class="loader-text"
    >
      <span>{{ $t("configurator.loader") }}</span>
    </div>
  </div>
</template>

<script setup>
  const props = defineProps({
    configPreloader: Boolean
  })
</script>

<style lang="scss">
  .config-loader {
    text-align: center;

    .lds-ring {
      width: 66px;
      height: 66px;

      &:before {
        content: '';
        border-radius: 50%;
        border: 2px solid var(--color-dark-blue);
        position: absolute;
        left: 0;
        width: calc(100% - 16px);
        height: calc(100% - 16px);
      }

      div {
        margin: 0;
        width: 50px;
        height: 50px;
        border-color: #D0D2D4 transparent transparent transparent;
      }
    }
  }
</style>